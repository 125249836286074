import React from "react";

const SvgDocument = props => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.962 6.41431L39.9619 17.7189C39.9612 18.8565 39.9607 19.7547 40.1189 20.5496C40.7633 23.7893 43.2957 26.3217 46.5354 26.9661C47.3303 27.1243 48.2285 27.1238 49.3661 27.1231L60.6703 27.1231L60.6701 43.9175C60.6702 47.8981 60.6702 51.1216 60.329 53.6599C59.9738 56.3014 59.2111 58.5478 57.4239 60.335C55.6367 62.1222 53.3903 62.8849 50.7488 63.2401C48.2105 63.5813 44.9871 63.5813 41.0065 63.5812H29.0006C25.0199 63.5813 21.7965 63.5813 19.2581 63.2401C16.6166 62.8849 14.3702 62.1222 12.583 60.335C10.7958 58.5478 10.0331 56.3014 9.67796 53.6599C9.33668 51.1215 9.33673 47.8981 9.33679 43.9174V26.0783C9.33673 22.0976 9.33668 18.8743 9.67796 16.3359C10.0331 13.6944 10.7958 11.448 12.583 9.6608C14.3702 7.87359 16.6166 7.11086 19.2581 6.75572C21.7965 6.41444 25.0199 6.41449 29.0007 6.41456L39.962 6.41431ZM18.0868 37.9168C18.0868 36.6282 19.1315 35.5835 20.4201 35.5835H43.7535C45.0421 35.5835 46.0868 36.6282 46.0868 37.9168C46.0868 39.2055 45.0421 40.2502 43.7535 40.2502H20.4201C19.1315 40.2502 18.0868 39.2055 18.0868 37.9168ZM20.4144 44.3335C19.1258 44.3335 18.0811 45.3782 18.0811 46.6668C18.0811 47.9555 19.1258 49.0002 20.4144 49.0002H34.9978C36.2864 49.0002 37.3311 47.9555 37.3311 46.6668C37.3311 45.3782 36.2864 44.3335 34.9978 44.3335H20.4144Z"
      />
      <path d="M44.6286 6.48322V17.4981C44.6286 18.9651 44.6399 19.3579 44.6959 19.6392C44.972 21.0276 46.0574 22.113 47.4458 22.3891C47.7271 22.4451 48.1199 22.4564 49.587 22.4564H60.4828C60.4045 22.0211 60.2937 21.6006 60.137 21.1885C59.6004 19.777 58.5959 18.6492 57.3563 17.2574L51.6323 10.8178C50.1805 9.18071 49.0029 7.85282 47.3961 7.13127C46.5345 6.74437 45.6246 6.56551 44.6286 6.48322Z" />
    </svg>
  );
};

export default SvgDocument;
