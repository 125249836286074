import React, { createContext, useState } from "react";

import { initialLatitude, initialLongitude } from "../helpers/common";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [isModalContactUs, setIsModalContactUs] = useState(false);
  const [isLoadNews, seIsLoadNews] = useState(false);

  const [changedCoords, setChangedCoords] = useState({
    lat: initialLatitude,
    lng: initialLongitude,
  });

  const [blogNews, setBlogNews] = useState([]);
  const [blogNewsObj, setBlogNewsObj] = useState({});

  return (
    <AppContext.Provider
      value={{
        isModalContactUs,
        setIsModalContactUs,
        changedCoords,
        setChangedCoords,
        blogNews,
        setBlogNews,
        blogNewsObj,
        setBlogNewsObj,
        isLoadNews,
        seIsLoadNews,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
