import React from "react";

const SvgLine = ({ stroke, ...props }) => {
  return (
    <svg width="1" height="16" viewBox="0 0 1 16" fill="none" {...props}>
      <line x1="0.5" y1="16" x2="0.5" stroke={stroke} />
    </svg>
  );
};

export default SvgLine;
