const routes = {
  houses: "/houses",
  about: "/about",
  blog: "/blog",
  projects: "/projects",
  contacts: "/contacts",
  home: "/",
  vacancies: "/vacancies",
};

export default routes;
