import React from "react";

const SvgClose = props => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="currentColor" {...props}>
      <path d="M1.5 20.9993L21.4992 1.00008" strokeWidth="2" />
      <path d="M21.5 21L1.50081 1.00081" strokeWidth="2" />
    </svg>
  );
};

export default SvgClose;
