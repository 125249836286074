import React from "react";

const SvgGraph = props => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" {...props}>
      <path d="M42.7371 6.41675C41.4484 6.41675 40.4037 7.46142 40.4037 8.75008C40.4037 10.0387 41.4484 11.0834 42.7371 11.0834H46.3641L27.6933 27.6796C27.2335 28.0883 26.9655 28.3253 26.7558 28.49C26.6478 28.5749 26.5907 28.6116 26.5707 28.6237C26.4294 28.6758 26.2734 28.6712 26.1354 28.6109C26.1161 28.5977 26.0613 28.5577 25.9585 28.4666C25.7588 28.2898 25.5051 28.0375 25.0701 27.6025L25.0275 27.5599C24.6266 27.1589 24.2537 26.786 23.9196 26.4911C23.5598 26.1734 23.1321 25.8423 22.5942 25.5972C21.2116 24.9674 19.6241 24.9674 18.2415 25.5972C17.7036 25.8423 17.2759 26.1734 16.9161 26.4911C16.582 26.786 16.2092 27.1588 15.8083 27.5598L10.0179 33.3502C9.10671 34.2614 9.10671 35.7388 10.0179 36.65C10.9292 37.5612 12.4065 37.5612 13.3178 36.65L19.0654 30.9024C19.5236 30.4441 19.7927 30.1765 20.0046 29.9895C20.114 29.8929 20.1725 29.8506 20.1931 29.8366C20.3369 29.7765 20.4988 29.7765 20.6426 29.8366C20.6632 29.8506 20.7217 29.8929 20.8311 29.9895C21.0429 30.1765 21.3121 30.4441 21.7703 30.9024L21.943 31.0758C22.5866 31.7231 23.2867 32.4272 24.1288 32.8245C25.4499 33.4478 26.9709 33.4925 28.3264 32.9478C29.1904 32.6007 29.9306 31.9389 30.6111 31.3305L30.7937 31.1675L50.1679 13.9461V17.9163C50.1679 19.2049 51.2125 20.2496 52.5012 20.2496C53.7899 20.2496 54.8345 19.2049 54.8345 17.9163V8.75008C54.8345 7.46142 53.7899 6.41675 52.5012 6.41675H42.7371Z" />
      <path d="M52.5012 23.9167C49.6017 23.9167 47.2512 26.2673 47.2512 29.1667V58.3334C47.2512 61.2329 49.6017 63.5834 52.5012 63.5834H58.3345C61.234 63.5834 63.5845 61.2329 63.5845 58.3334V29.1668C63.5845 26.2673 61.234 23.9167 58.3345 23.9167H52.5012Z" />
      <path d="M26.8345 40.8334C26.8345 37.9339 29.185 35.5834 32.0845 35.5834H37.9179C40.8173 35.5834 43.1679 37.9339 43.1679 40.8334V58.3334C43.1679 61.2329 40.8173 63.5834 37.9179 63.5834H32.0845C29.185 63.5834 26.8345 61.2329 26.8345 58.3334V40.8334Z" />
      <path d="M6.41785 49.5834C6.41785 46.6839 8.76835 44.3334 11.6678 44.3334H17.5012C20.4007 44.3334 22.7512 46.6839 22.7512 49.5834V58.3334C22.7512 61.2329 20.4007 63.5834 17.5012 63.5834H11.6678C8.76835 63.5834 6.41785 61.2329 6.41785 58.3334V49.5834Z" />
    </svg>
  );
};

export default SvgGraph;
