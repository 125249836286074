import React from "react";

const SvgTelegram = props => {
  return (
    <svg width="29" height="24" viewBox="0 0 29 24" fill="currentColor" {...props}>
      <path d="M26.8767 0.157806L1.52764 9.93284C-0.202331 10.6277 -0.192323 11.5928 1.21024 12.0231L7.71835 14.0533L22.7762 4.55278C23.4882 4.11958 24.1387 4.35262 23.604 4.82729L11.4042 15.8376H11.4013L11.4042 15.839L10.9552 22.5473C11.6129 22.5473 11.9032 22.2456 12.272 21.8896L15.4331 18.8157L22.0085 23.6725C23.2209 24.3402 24.0916 23.997 24.3932 22.5502L28.7096 2.20803C29.1514 0.436602 28.0333 -0.365474 26.8767 0.157806Z" />
    </svg>
  );
};

export default SvgTelegram;
