import Image404 from "./404.png";
import ClientLogo from "./ClientLogo1.png";
import LandBlock1 from "./LandBlock1.png";
import LandBlock2 from "./LandBlock2.png";
import LandBlock from "./LandBlock.png";
import AboutCompany2 from "./aboutCompany2.png";
import AboutCompany3 from "./aboutCompany3.png";
import AboutCompany from "./aboutCompany.png";
import allProjects1 from "./allProjects1.png";
import allProjects2 from "./allProjects2.png";
import allProjects3 from "./allProjects3.png";
import allProjects4 from "./allProjects4.png";
import allProjects5 from "./allProjects5.png";
import allProjects6 from "./allProjects6.png";
import allProjects7 from "./allProjects7.png";
import allProjects8 from "./allProjects8.png";
import allProjects from "./allProjects.png";
import Awards from "./awards.png";
import BlogPage1 from "./blogPage1.png";
import BlogPage2 from "./blogPage2.png";
import BlogPage3 from "./blogPage3.png";
import BlogPage4 from "./blogPage4.png";
import BlogPage5 from "./blogPage5.png";
import BlogPage6 from "./blogPage6.png";
import BlogPage7 from "./blogPage7.png";
import BlogPage9 from "./blogPage9.png";
import BlogPage10 from "./blogPage10.png";
import BlogPage11 from "./blogPage11.png";
import BlogPage12 from "./blogPage12.png";
import BlogPage from "./blogPage.png";
import ClientAvatar from "./clientAvatar1.png";
import ContactsBlock from "./contactsBlock.png";
import House1 from "./house1.png";
import House2 from "./house2.png";
import House3 from "./house3.png";
import House4 from "./house4.png";
import House5 from "./house5.png";
import House6 from "./house6.png";
import House from "./house.png";
import HouseBigImage from "./houseBigImg.png";
import HouseCard from "./houseCard.jpg";
import HouseSmallImage1 from "./houseSmallImg1.png";
import HouseSmallImage from "./houseSmallImg.png";
import OurHouses1 from "./houses_1.png";
import OurHouses2 from "./houses_2.png";
import OurHouses3 from "./houses_3.png";
import howWeWork1 from "./howWeWork1.png";
import howWeWork2 from "./howWeWork2.png";
import InvitationImg from "./invitationImg.png";
import LogoFooter from "./logoFooter.png";
import LogoHeader from "./logoHeader.png";
import MainBlogNews1 from "./mainBlogNews1.png";
import MainBlogNews2 from "./mainBlogNews2.png";
import MainBlogNews3 from "./mainBlogNews3.png";
import MainBlogNews4 from "./mainBlogNews4.png";
import MainProject1 from "./mainProject1.png";
import MainProject2 from "./mainProject2.png";
import MainProject from "./mainProject.png";
import ManageHousesBlock1 from "./manageHouses1.png";
import ManageHousesBlock2 from "./manageHouses2.png";
import ManageHousesBlock from "./manageHouses.png";
import News1 from "./news_1.png";
import News2 from "./news_2.png";
import News3 from "./news_3.png";
import OurMission from "./ourMission.png";
import ParallaxImage1 from "./parallaxImage1.png";
import ParallaxImage from "./parallaxImage.png";
import ProjectImages1 from "./progectImages1.png";
import ProjectImages2 from "./progectImages2.png";
import ProjectImages3 from "./progectImages3.png";
import ProjectImages from "./progectImages.png";
import ProjectsMain from "./projectsMain.png";
import RepairBlock1 from "./repairBlock1.png";
import RepairBlock2 from "./repairBlock2.png";
import RepairBlock from "./repairBlock.png";
import TeamPhoto1 from "./teamPhoto1.png";
import TeamPhoto2 from "./teamPhoto2.png";
import TeamPhoto3 from "./teamPhoto3.png";
import TeamPhoto4 from "./teamPhoto4.png";
import VacancyIcon1 from "./vacancyIcon1.svg";
import VacancyIcon2 from "./vacancyIcon2.svg";
import VacancyIcon from "./vacancyIcon.svg";
import WeDoing1 from "./weDoing1.png";
import WeDoing from "./weDoing.png";

const images = {
  LogoFooter,
  LogoHeader,
  AboutCompany,
  AboutCompany2,
  AboutCompany3,
  Image404,
  ParallaxImage,
  ParallaxImage1,
  OurMission,
  ClientLogo,
  TeamPhoto1,
  TeamPhoto2,
  TeamPhoto3,
  TeamPhoto4,
  Awards,
  ClientAvatar,
  WeDoing,
  WeDoing1,
  howWeWork1,
  howWeWork2,
  ContactsBlock,
  RepairBlock,
  RepairBlock1,
  RepairBlock2,
  LandBlock,
  LandBlock1,
  LandBlock2,
  ManageHousesBlock,
  ManageHousesBlock1,
  ManageHousesBlock2,
  allProjects,
  allProjects1,
  allProjects2,
  allProjects3,
  allProjects4,
  allProjects5,
  allProjects6,
  allProjects7,
  allProjects8,
  MainProject,
  MainProject1,
  MainProject2,
  News1,
  News2,
  News3,
  ProjectsMain,
  ProjectImages,
  ProjectImages1,
  ProjectImages2,
  ProjectImages3,
  VacancyIcon,
  VacancyIcon1,
  VacancyIcon2,
  InvitationImg,
  BlogPage,
  BlogPage12,
  OurHouses1,
  OurHouses2,
  OurHouses3,
  BlogPage1,
  BlogPage2,
  BlogPage3,
  BlogPage9,
  BlogPage10,
  BlogPage11,
  BlogPage4,
  BlogPage5,
  BlogPage6,
  BlogPage7,
  MainBlogNews1,
  MainBlogNews2,
  MainBlogNews3,
  MainBlogNews4,
  HouseSmallImage,
  HouseSmallImage1,
  HouseBigImage,
  House,
  House1,
  House2,
  House3,
  House4,
  House5,
  House6,
  HouseCard,
};

export default images;
