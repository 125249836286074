import React from "react";

const SvgShield = props => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.3599 15.3864L38.7084 7.82147C36.1527 6.72618 33.2598 6.72618 30.7041 7.82147L13.0526 15.3864C11.8074 15.9201 11 17.1445 11 18.4992V36.2887C11 42.8509 14.1692 49.0092 19.5091 52.8234L30.7694 60.8665C33.1244 62.5487 36.288 62.5487 38.6431 60.8665L49.9034 52.8234C55.2433 49.0092 58.4125 42.8509 58.4125 36.2887V18.4992C58.4125 17.1445 57.6051 15.9201 56.3599 15.3864ZM47.5105 28.5424C48.6789 27.0819 48.4421 24.9508 46.9816 23.7823C45.5211 22.6139 43.3899 22.8507 42.2215 24.3112L31.0388 38.2897L26.9411 34.192C25.6185 32.8694 23.4742 32.8694 22.1517 34.192C20.8291 35.5145 20.8291 37.6588 22.1517 38.9813L27.5871 44.4168C29.728 46.5577 33.2545 46.3624 35.1459 43.9982L47.5105 28.5424Z"
      />
    </svg>
  );
};

export default SvgShield;
