import React from "react";

const SvgLightning = props => {
  return (
    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" {...props}>
      <path d="M27.5757 39.295L27.1662 39.2578C21.9426 38.7829 19.3308 38.5455 18.5012 36.7588C17.6715 34.9721 19.1755 32.8236 22.1834 28.5266L34.4327 11.0275C36.4198 8.18884 37.4133 6.7695 38.2702 7.07131C39.1271 7.37312 39.0119 9.1018 38.7814 12.5592L38.0865 22.982C37.8768 26.1282 37.7719 27.7012 38.6759 28.7599C39.58 29.8185 41.1501 29.9613 44.2903 30.2468L44.6998 30.284C49.9234 30.7589 52.5352 30.9963 53.3648 32.783C54.1944 34.5697 52.6905 36.7182 49.6826 41.0152L37.4333 58.5143C35.4462 61.3529 34.4527 62.7723 33.5958 62.4705C32.7389 62.1687 32.8541 60.44 33.0846 56.9826L33.7795 46.5598C33.9892 43.4136 34.0941 41.8405 33.1901 40.7819C32.286 39.7232 30.7159 39.5805 27.5757 39.295Z" />
      <path
        d="M58.1563 7.41895L53.0278 14.2569H59.8658L54.7373 21.0949"
        strokeWidth="3.41899"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1285 48.4468L12 55.2848H18.838L13.7095 62.1227"
        strokeWidth="3.41899"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgLightning;
