import React from "react";

const SvgArrow = props => {
  return (
    <svg viewBox="0 0 9 15" fill="none" {...props}>
      <path d="M1.5 1.4375L7.5 7.4375L1.5 13.4375" strokeWidth="2" />
    </svg>
  );
};

export default SvgArrow;
